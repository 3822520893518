<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :defaultQueryParam="defaultQueryParams"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getItmsMetaPage"
      :deleteFun="deleteItmsMetaInfo"
      :createFun="createItmsMetaInfo"
      :updateFun="updateItmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getItmsMetaPage, createItmsMetaInfo, updateItmsMetaInfo, deleteItmsMetaInfo } from '@/api/itms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '运输条款', name: 'name', fieldType: '', queryType: '%'
        },
        {
          label: '英文名称', name: 'eng_name', fieldType: '', queryType: '%'
        }
      ],
      defaultQueryParams: {
        type: 11
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '运输条款',
          dataIndex: 'name'
        },
        {
          title: '英文名称',
          dataIndex: 'eng_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入运输条款', trigger: 'blur' }
        ]
      },
      modelTitle: '运输条款',
      formFields: [
        {
          label: '运输条款', name: 'name', type: 'default'
        },
        {
          label: '英文名称', name: 'eng_name', type: 'default'
        },
        {
          label: '', name: 'type', type: 'hidden', defaultValue: 11
        }
      ],
      moduleName: 'itms_trans_com'
    }
  },
  methods: {
    getItmsMetaPage,
    createItmsMetaInfo,
    updateItmsMetaInfo,
    deleteItmsMetaInfo
  }
}
</script>
